import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

// Pinia config
import { createPinia } from "pinia";

// Css
import "@/assets/css/main.scss";

// Vuetify
import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import "@mdi/font/css/materialdesignicons.css";

const vuetify = createVuetify({
  components,
  directives,
});

// Localization
import { createI18n } from "vue-i18n";

// Transitions Language
import ar from "./local/ar";
import en from "./local/en";
const messages = {
  en,
  ar,
};

// install I18n
export const i18n = new createI18n({
  legacy: false,
  locale: "ar",
  fallbackLocale: "en",
  globalInjection: true,
  messages,
});

createApp(App)
  .use(i18n)
  .use(vuetify)
  .use(createPinia())
  .use(router)
  .mount("#app");
