<template>
  <div>
    <div
      class="fixed-whats bg-green"
      style="
        position: fixed;
        right: 50px;
        bottom: 50px;
        z-index: 10000;
        box-shadow: black 0px 0px 18px -5px;
        border-radius: 50%;
        padding: 7px;
      "
    >
      <a href="https://wa.me/+966508760634" target="_blank">
        <v-icon color="white" size="50">mdi-whatsapp</v-icon>
      </a>
    </div>
    <v-layout :class="{ rtl: i18n.locale == 'ar' }">
      <v-app-bar height="100">
        <v-container class="py-0">
          <v-row class="align-center justify-space-between">
            <v-col cols="4">
              <div class="logo_parent">
                <img src="/second_hero.jpg" alt="" width="300" />
              </div>
            </v-col>
            <v-col cols="8" class="d-none d-md-block">
              <div class="links">
                <a
                  @click.prevent
                  href="#hero"
                  class="hero"
                  @click="toSection"
                  data-section="#hero"
                  :class="{ active: currentSection == 'hero' }"
                  >{{ t("main.nav.home") }}</a
                >
                <a
                  @click.prevent
                  href="#plan"
                  class="plan"
                  @click="toSection"
                  data-section="#plan"
                  :class="{ active: currentSection == 'plan' }"
                  >{{ t("main.nav.plan") }}</a
                >
                <a
                  @click.prevent
                  href="#second-hero"
                  data-section="#second-hero"
                  :class="{ active: currentSection == 'second-hero' }"
                  class="second-hero"
                  @click="toSection"
                  >{{ t("main.nav.why_us") }}</a
                >
                <a
                  @click.prevent
                  href="#services"
                  data-section="#services"
                  class="services"
                  :class="{ active: currentSection == 'services' }"
                  @click="toSection"
                  >{{ t("main.nav.services") }}</a
                >
                <a
                  @click.prevent
                  href="#works"
                  class="works"
                  @click="toSection"
                  :class="{ active: currentSection == 'works' }"
                  data-section="#works"
                  >{{ t("main.nav.works") }}</a
                >
                <a
                  @click.prevent
                  href="#reviews"
                  data-section="#reviews"
                  class="reviews"
                  :class="{ active: currentSection == 'reviews' }"
                  @click="toSection"
                  >{{ t("main.nav.reviews") }}</a
                >

                <div class="swap-lang">
                  <v-tooltip
                    activator="parent"
                    :location="i18n.locale == 'en' ? 'right' : 'left'"
                    >{{
                      i18n.locale == "en" ? "العربية" : "English"
                    }}</v-tooltip
                  >
                  <img
                    src="/egypt.png"
                    alt=""
                    width="30"
                    v-if="i18n.locale == 'en'"
                    height="30"
                    @click="changeLang"
                  />
                  <img
                    src="/usa.png"
                    alt=""
                    v-if="i18n.locale == 'ar'"
                    width="30"
                    height="30"
                    @click="changeLang"
                  />
                </div>
              </div>
            </v-col>
            <v-col cols="2" class="d-block d-md-none text-end">
              <v-app-bar-nav-icon id="links_menu"></v-app-bar-nav-icon>
              <v-menu activator="#links_menu" width="100dvw" offset="20 50">
                <v-list>
                  <div class="links flex-column py-5">
                    <a
                      @click.prevent
                      href="#hero"
                      class="hero"
                      @click="toSection"
                      data-section="#hero"
                      :class="{ active: currentSection == 'hero' }"
                      >{{ t("main.nav.home") }}</a
                    >
                    <a
                      @click.prevent
                      href="#plan"
                      class="plan"
                      @click="toSection"
                      data-section="#plan"
                      :class="{ active: currentSection == 'plan' }"
                      >{{ t("main.nav.plan") }}</a
                    >
                    <a
                      @click.prevent
                      href="#second-hero"
                      data-section="#second-hero"
                      :class="{ active: currentSection == 'second-hero' }"
                      class="second-hero"
                      @click="toSection"
                      >{{ t("main.nav.why_us") }}</a
                    >
                    <a
                      @click.prevent
                      href="#services"
                      data-section="#services"
                      class="services"
                      :class="{ active: currentSection == 'services' }"
                      @click="toSection"
                      >{{ t("main.nav.services") }}</a
                    >
                    <a
                      @click.prevent
                      href="#works"
                      class="works"
                      @click="toSection"
                      :class="{ active: currentSection == 'works' }"
                      data-section="#works"
                      >{{ t("main.nav.works") }}</a
                    >
                    <a
                      @click.prevent
                      href="#reviews"
                      data-section="#reviews"
                      class="reviews"
                      :class="{ active: currentSection == 'reviews' }"
                      @click="toSection"
                      >{{ t("main.nav.reviews") }}</a
                    >
                    <div class="swap-lang">
                      <img
                        src="/egypt.png"
                        alt=""
                        width="30"
                        v-if="i18n.locale == 'en'"
                        height="30"
                        @click="changeLang"
                      />
                      <img
                        src="/usa.png"
                        alt=""
                        v-if="i18n.locale == 'ar'"
                        width="30"
                        height="30"
                        @click="changeLang"
                      />
                    </div>
                  </div>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
        </v-container>
      </v-app-bar>
      <v-main>
        <router-view />
      </v-main>
    </v-layout>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";

import { getCurrentInstance } from "vue";

const i18n = getCurrentInstance().appContext.config.globalProperties.$i18n;

const { t } = useI18n();

const currentSection = ref(null);

// Methods
const toSection = (e) => {
  e.preventDefault();
  const section = document.querySelector(e.target.dataset.section);
  document.documentElement.scrollTop = section.offsetTop - 100;
};

const checkCurrentSection = () => {
  document.querySelectorAll(".section").forEach((el) => {
    if (window.scrollY >= el.offsetTop - 300) {
      currentSection.value = el.getAttribute("id");
    }
  });
};

const changeLang = () => {
  localStorage.setItem("lang", i18n.locale == "en" ? "ar" : "en");
  setTimeout(() => {
    window.location.reload();
  }, 200);
};

onMounted(() => {
  window.addEventListener("scroll", checkCurrentSection);
  if (localStorage.getItem("lang")) {
    i18n.locale = localStorage.getItem("lang");
  }
  i18n.locale == "en"
    ? (document.querySelector("*").style.fontFamilty = "monospace")
    : (document.querySelector("*").style.fontFamilty = "Tajwal sans-serif");
});
</script>

<style lang="scss">
@font-face {
  font-family: Tajwal;
  src: url(../public/fonts/Tajawal-Regular.ttf);
}

* {
  scroll-behavior: smooth;
}
.rtl_parent {
  * {
    direction: rtl !important;
    font-family: system-ui;
  }
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.err_message {
  color: indianred;
  font-size: 12px;
  font-weight: bold;
  display: block;
}
.links {
  a {
    text-decoration: unset;
    color: #005c97;
    &.active {
      color: red !important;
    }
  }
  .swap-lang {
    img {
      cursor: pointer;
      border-radius: 50%;
    }
  }
}

.rtl {
  * {
    direction: rtl !important;
    font-family: system-ui !important;
  }
}
</style>
